<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            class="firstSectionColumn"
            role="columnheader"
            style="width: 1%"
          ></th>
          <th
            v-for="header in isOwner ? liveHeadersOwner : liveHeaders"
            :key="header.value"
            role="columnheader"
            :class="[header.clickHandler ? 'clickable' : '']"
            :style="{ textAlign: header.align, width: header.width }"
            @click="header.clickHandler ? header.clickHandler() : null"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <Section
          v-for="liveTableEntry in liveTableEntries"
          :key="
            liveTableEntry.competitorData
              ? `${liveTableEntry.sectionIndex}-${
                  liveTableEntry.runIndex
                }-${liveTableEntry.competitorData.id ||
                  liveTableEntry.competitorData.competitorId}`
              : `${liveTableEntry.sectionIndex}-${liveTableEntry.runIndex}`
          "
          :entry="liveTableEntry"
          :event="event"
          :heat="heat"
          :columns="isOwner ? liveHeadersOwner : liveHeaders"
          :liveTime="liveTimes[curLiveTimeIndex]"
          :leaderData="sectionLeaderData"
          :serverTimestamp="serverTimestamp"
          :decimals="precision"
          :sectionItems="getSectionItems(liveTableEntry.sectionIndex)"
          :sectionItemsPerPage="sectionItemsPerPage"
          :sectionIndexOffset="sectionIndexOffsets[liveTableEntry.sectionIndex]"
          @decreaseOffset="decreaseSectionOffset"
          @increaseOffset="increaseSectionOffset"
          @cancel-action="
            (heatId, competitorId, reason) =>
              $emit('cancel-action', heatId, competitorId, reason)
          "
          @open-edit-action="
            competitor => $emit('open-edit-action', competitor)
          "
          @open-edit-run-action="
            (heatId, competitorId) =>
              $emit('open-edit-run-action', heatId, competitorId)
          "
          @restart-action="
            (heatId, competitorId, includeStartgroup) =>
              $emit('restart-action', heatId, competitorId, includeStartgroup)
          "
          @edit-competitor-comment="
            competitorId => $emit('edit-competitor-comment', competitorId)
          "
        />
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Section from "@/components/Section";
import whitelist from "@/mixins/whitelist";
import headers from "@/mixins/headers";

export default {
  name: "LiveTable",
  props: {
    event: {
      type: Object,
      require: true
    },
    heat: {
      type: Object,
      required: true
    },
    serverTimestamp: {
      type: Number,
      default: null
    }
  },
  components: {
    Section
  },
  data() {
    return {
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"],
      liveTimes: [
        {
          value: "RUN_TIME",
          text: "runTime"
        },
        {
          value: "TOTAL_TIME",
          text: "totalTime"
        }
      ],
      curLiveTimeIndex: 0,
      sectionItemsPerPage: 5,
      sectionIndexOffsets: this.event.setup.sections.slice(0, -1).map(el => 0)
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById",
      getFieldPreferenceById: "settings/getFieldPreferenceById"
    }),
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    liveHeaders() {
      const headers = Array.from(this.headers());

      if (
        this.event.setup.speedEvent === undefined ||
        this.event.setup.speedEvent === false
      ) {
        headers.push(
          {
            text: this.$i18n.t(
              `competitors.tables.${this.liveTimes[this.curLiveTimeIndex].text}`
            ),
            value: "time",
            align: "right",
            clickHandler: this.liveTimeClickHandler
          },
          {
            text: this.$i18n.t("competitors.tables.diff"),
            value: "diff",
            align: "right"
          }
        );
      }

      const speedSections = this.event.setup.sections.filter(el =>
        el.hasOwnProperty("distance")
      );

      if (speedSections.length > 0) {
        headers.push({
          text: this.$i18n.t("competitors.tables.speed"),
          value: "speed",
          align: "right"
        });
      }

      if (this.event.setup.speedEvent === true) {
        headers.push({
          text: this.$i18n.t("competitors.tables.diff"),
          value: "diff",
          align: "right"
        });
      }

      headers.push({
        text: this.$i18n.t("competitors.tables.rank"),
        value: "rank",
        align: "right"
      });

      if (this.heat.competitorInformation.length > 0 || this.isOwner) {
        headers.push({
          text: this.$i18n.t("competitors.tables.note"),
          value: "note",
          align: "right"
        });
      }

      if (this.isOwner && this.event.setup.useTolerances) {
        headers.push({
          text: this.$i18n.t("competitors.tables.reference"),
          value: "excludeFromReferenceRun",
          align: "right",
          width: "1%"
        });
      }

      headers.push({
        value: "pagination",
        sortable: false,
        width: "1%"
      });

      return headers;
    },
    liveHeadersOwner() {
      const headers = Array.from(this.liveHeaders);
      headers.push({
        value: "actions",
        sortable: false,
        width: "1%"
      });
      return headers;
    },
    liveTableEntries() {
      const entries = new Array();

      // next competitor according to start list
      entries.push({
        sectionId: "START",
        sectionIndex: 0,
        runIndex: 0,
        type: "start",
        competitorData: this.competitorStart,
        actions: ["competitorEdit", "runEdit", "dsq", "dns"]
      });

      // competitors who already started but didn't pass any intermediate time yet
      const competitors = this.competitorsOnTrack();
      competitors.map((competitorData, index) => {
        entries.push({
          sectionIndex: 0,
          runIndex: index + 1,
          sectionId: "START",
          type: "track",
          competitorData,
          actions: ["competitorEdit", "runEdit", "runRestart", "dsq", "dnf"]
        });
      });

      // intermediate section x
      // +
      // competitors who already started but did pass intermediate section x but not the other intermediate sections
      this.event.setup.sections.slice(1, -1).map((section, sectionIndex) => {
        const sectionType = section.type;
        entries.push({
          sectionId: sectionType,
          sectionIndex: sectionIndex + 1,
          runIndex: 0,
          type: "intermediate",
          competitorData: this.competitorOnSection(sectionType),
          actions: ["competitorEdit", "runEdit", "runRestart", "dsq", "dnf"]
        });
        const competitors = this.competitorsOnTrack(sectionType);
        competitors.map((competitorData, runIndex) => {
          entries.push({
            sectionId: sectionType,
            sectionIndex: sectionIndex + 1,
            runIndex: runIndex + 1,
            sectionId: sectionType,
            type: "track",
            competitorData,
            actions: ["competitorEdit", "runEdit", "runRestart", "dsq", "dnf"]
          });
        });
      });

      // last competitor who finished
      entries.push({
        sectionId: "FINISH",
        sectionIndex: this.event.setup.sections.length - 1,
        runIndex: 0,
        sectionId: "FINISH",
        type: "finish",
        competitorData: this.competitorFinish,
        actions: ["competitorEdit", "runEdit", "runRestart", "dsq"]
      });

      return entries;
    },
    leader() {
      if (
        this.heat &&
        this.heat.ranking.entries.length > 0 &&
        this.heat.ranking.entries[0].runTime > 0 &&
        this.heat.ranking.entries[0].state !== "CANCELLED"
      ) {
        return this.heat.ranking.entries[0];
      }

      return null;
    },
    heatLeaderData() {
      if (this.heat && this.leader) {
        const index = this.heat.heatCycle.entries.findIndex(
          entry => entry.competitorId === this.leader.competitorId
        );

        if (index >= 0) {
          const leaderEntry = this.heat.heatCycle.entries[index];
          return {
            ...this.leader,
            ...leaderEntry
          };
        }
      }

      return null;
    },
    eventLeaderData() {
      if (
        this.event &&
        this.event.overallRanking.entries &&
        this.event.overallRanking.entries.length > 0 &&
        this.heat
      ) {
        const eventRankingLeader = this.event.overallRanking.entries[0];
        const heatCycleEntry = this.heat.heatCycle.entries.find(
          el => el.competitorId === eventRankingLeader.competitorId
        );

        if (heatCycleEntry !== undefined) {
          return {
            ...eventRankingLeader,
            ...heatCycleEntry
          };
        }
      }

      return null;
    },
    sectionLeaderData() {
      switch (this.liveTimes[this.curLiveTimeIndex].value) {
        case "RUN_TIME":
          return this.heatLeaderData;
        case "TOTAL_TIME":
          return this.eventLeaderData;
        default:
          return null;
      }
    },
    competitorStart() {
      if (this.heat) {
        const competitors = new Set();
        this.heat.heatCycle.entries.map(entry =>
          competitors.add(entry.competitorId)
        );
        for (const c of this.heat.competitors) {
          if (!competitors.has(c)) {
            const competitor = this.getCompetitorById(c);
            if (competitor) {
              return competitor;
            } else {
              break;
            }
          }
        }
      }
      return null;
    },
    competitorFinish() {
      if (this.heat && this.heat.heatCycle.entries.length > 0) {
        const finishedCompetitors = this.heat.heatCycle.entries
          .filter(entry => entry.data.sections.hasOwnProperty("FINISH"))
          .sort(
            (b, a) =>
              a.data.sections["FINISH"].timestamp_100ns -
              b.data.sections["FINISH"].timestamp_100ns
          );

        if (finishedCompetitors.length > 0) {
          const competitor = finishedCompetitors[0];
          const entry = this.heat.ranking.entries.find(
            entry => entry.competitorId === competitor.competitorId
          );

          return {
            ...competitor,
            rank: entry === undefined ? "-" : entry.rank,
            runTime: entry === undefined ? 0 : entry.runTime
          };
        }
      }
      return null;
    },
    intermediateSections() {
      return this.event.setup.sections.filter(
        section => section.type !== "START" && section.type !== "FINISH"
      );
    },
    finishSection() {
      return this.event.setup.sections[this.event.setup.sections.length - 1];
    },
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );

      return precision >= 0 ? precision : 2;
    }
  },
  mixins: [whitelist, headers],
  methods: {
    decreaseSectionOffset(sectionIndex) {
      if (this.sectionIndexOffsets[sectionIndex] > 0) {
        this.sectionIndexOffsets[sectionIndex]--;
      }
    },
    increaseSectionOffset(sectionIndex) {
      this.sectionIndexOffsets[sectionIndex]++;
    },
    liveTimeClickHandler() {
      this.curLiveTimeIndex =
        (this.curLiveTimeIndex + 1) % this.liveTimes.length;
    },
    competitorsOnTrack(section = "START") {
      if (this.heat) {
        return this.heat.heatCycle.entries
          .filter(entry => {
            const sectionKeys = Object.keys(entry.data.sections);
            if (
              entry.state !== "STARTED" ||
              sectionKeys[sectionKeys.length - 1] !== section
            ) {
              return false;
            }
            // TODO: Clarify if past times are needed (base time)
            const runTime =
              this.serverTimestamp -
              entry.data.sections["START"].timestamp_100ns +
              this.event.setup.syncDelta;
            return runTime >= 0;
          })
          .sort((a, b) => {
            if (
              a.data.sections["START"].timestamp_100ns >
              b.data.sections["START"].timestamp_100ns
            )
              return -1;
            if (
              a.data.sections["START"].timestamp_100ns <
              b.data.sections["START"].timestamp_100ns
            )
              return 1;
            return 0;
          });
      }
      return null;
    },
    competitorOnSection(section) {
      const passedCompetitors = this.heat.heatCycle.entries
        .filter(
          entry =>
            entry.data.sections[section] &&
            entry.data.sections[section].timestamp_100ns > 0
        )
        .sort((a, b) => {
          if (
            a.data.sections[section].timestamp_100ns >
            b.data.sections[section].timestamp_100ns
          )
            return 1;
          if (
            a.data.sections[section].timestamp_100ns <
            b.data.sections[section].timestamp_100ns
          )
            return -1;
          return 0;
        });

      if (passedCompetitors.length > 0) {
        return passedCompetitors[passedCompetitors.length - 1];
      }
      return null;
    },
    diffColor(diffTime) {
      if (diffTime.startsWith("+")) {
        return "red--text";
      } else if (diffTime.startsWith("-") && String(diffTime).length > 1) {
        return "green--text";
      }

      return "";
    },
    getCancellationReason(competitorId) {
      const run = this.heat.heatCycle.entries.find(
        run => run.competitorId === competitorId
      );

      if (run !== undefined) {
        return run.data.cancellation;
      }

      return "";
    },
    getSectionItems(sectionIndex) {
      return (
        this.liveTableEntries.filter(el => el.sectionIndex === sectionIndex)
          .length - 1
      );
    }
  }
};
</script>

<style scoped>
.firstSectionColumn {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
.v-data-table--dense /deep/ thead > tr > th,
.v-data-table--dense /deep/ tbody > tr > td {
  padding: 0 10px !important;
}
</style>
