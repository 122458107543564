<template>
  <v-card flat>
    <div v-if="heats && heats.length > 0" class="mt-4 mt-2">
      <v-slide-group
        v-model="selectedHeat"
        mandatory
        center-active
        show-arrows
        class="mx-1"
      >
        <v-slide-item
          v-for="(heat, heatIndex) in heats"
          :key="heat.id"
          :value="heatIndex"
          v-slot:default="{ active, toggle }"
        >
          <v-card flat class="ma-2">
            <v-card :color="active ? 'primary' : ''" @click="toggle" tile>
              <v-card-title class="pa-2" :class="{ 'white--text': active }">
                {{
                  heatIndex === 0 && isRegularity && isTargetTime
                    ? $t("competitors.tables.targetTime")
                    : heat.name
                }}
              </v-card-title>
              <v-card-subtitle class="pa-2" :class="{ 'white--text': active }">
                {{ $d(heat.startTime, "shortDateTime") }}
              </v-card-subtitle>
              <v-card-text
                class="pt-0 px-2 pb-2 text-uppercase"
                :class="{ 'white--text': active }"
              >
                {{ $t(`events.state.${heat.state.toLowerCase()}`) }}
              </v-card-text>
            </v-card>
            <v-card
              v-if="
                isOwner &&
                  event.state !== 'SCHEDULED' &&
                  event.state !== 'FINISHED'
              "
              class="d-flex flex-grow-1 justify-center"
              tile
            >
              <v-btn
                text
                class="flex-grow-1 ma-0 pa-0"
                :color="armedState(heatIndex) ? 'success' : 'error'"
                @click="updateHeatArmedStatus(heatIndex)"
              >
                {{
                  armedState(heatIndex)
                    ? $t("events.armed")
                    : $t("events.disarmed")
                }}
              </v-btn>
            </v-card>
          </v-card>
        </v-slide-item>
        <v-slide-item
          v-if="rankedHeats.length > 1"
          key="TOTAL"
          value="TOTAL"
          v-slot:default="{ active, toggle }"
        >
          <v-card
            :color="active ? 'primary' : ''"
            class="d-flex ma-2"
            :class="{
              'grey lighten-3': overallRankingEntries.length === 0 && !active
            }"
            @click="toggle"
          >
            <v-card-title
              class="pa-2"
              :class="{
                'grey--text text--darken-1':
                  overallRankingEntries.length === 0 && !active,
                'white--text': active
              }"
            >
              {{ $t("events.total") }}
            </v-card-title>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <div v-if="selectedHeat !== null && selectedHeat !== 'TOTAL'">
        <v-alert
          v-if="isOwner && activeHeat.competitors.length === 0"
          dense
          type="info"
          class="mx-4 mt-4"
        >
          {{ $t("events.notes.noHeatCompetitors") }}
        </v-alert>

        <div
          v-if="isOwner"
          class="d-flex justify-end flex-column flex-md-row ma-2 ma-md-4"
        >
          <LogDownloader
            :event="event"
            class="ma-1"
            @logDownload="handleLogDownload"
          />

          <v-btn small color="primary" class="ma-1" @click="showLog = !showLog">
            {{ $t("events.log") }}
            <v-icon>
              {{ showLog ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>

          <v-btn
            small
            color="primary"
            class="ma-1"
            @click="showTriggers = !showTriggers"
          >
            {{ $t("events.trigger") }}
            <v-icon>
              {{ showTriggers ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>

          <v-btn
            v-if="event.setup.useTolerances"
            small
            color="primary"
            class="ma-1"
            @click="showTolerances = !showTolerances"
          >
            {{ $t("events.tolerances.title") }}
            <v-icon>
              {{ showTolerances ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>

          <v-btn
            small
            color="warning"
            class="ma-1"
            :loading="heatIsLoading"
            @click="restartHeat"
          >
            {{ $t("events.restartHeat") }}
            <v-icon small right>mdi-replay</v-icon>
          </v-btn>
        </div>

        <v-expand-transition v-if="isOwner">
          <div v-show="showTriggers">
            <div
              class="d-flex flex-column flex-md-row align-stretch justify-space-between"
            >
              <v-card-title>
                {{ $t("events.trigger") }}
              </v-card-title>

              <div class="mx-2 mx-md-4 d-flex flex-column flex-md-row">
                <v-btn-toggle
                  v-model="autoUpdate"
                  active-class="success"
                  borderless
                  dense
                  class="px-1"
                >
                  <v-btn
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                    :value="true"
                    elevation="2"
                    small
                    class="my-1"
                    :class="{ 'white--text': autoUpdate }"
                  >
                    {{
                      autoUpdate
                        ? $t("forms.trigger.autoUpdateEnabled")
                        : $t("forms.trigger.autoUpdateDisabled")
                    }}
                  </v-btn>
                </v-btn-toggle>

                <v-btn
                  @click="handleTriggerReload"
                  color="primary"
                  class="ma-1"
                  small
                  :loading="loadingTriggers"
                >
                  {{ $t("forms.trigger.reload") }}
                </v-btn>

                <v-btn
                  @click="handleTriggerClear"
                  :color="clearTriggers.color"
                  :loading="clearTriggers.loading"
                  class="ma-1"
                  small
                >
                  {{ $t("events.clear") }}
                </v-btn>

                <v-btn
                  @click="handleTriggerReset"
                  :color="resetTriggers.color"
                  :loading="resetTriggers.loading"
                  class="ma-1"
                  small
                >
                  {{ $t("events.reset") }}
                </v-btn>
              </div>
            </div>

            <v-data-table
              :headers="triggerHeaders"
              :items="indexedTriggerWithDeviceData"
              :loading="loadingTriggers"
              mobile-breakpoint="0"
            >
              <template v-slot:body.prepend>
                <tr>
                  <td>
                    <v-text-field
                      v-model="search.trigger.deviceId"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="search.trigger.deviceName"
                      type="text"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="search.trigger.status"
                      :items="triggerStatus"
                      :item-text="item => $t(`trigger.table.${item.text}`)"
                      clearable
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      v-model="search.trigger.startNumber"
                      type="number"
                      min="1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="search.trigger.type"
                      :items="triggerTypes"
                      :item-text="item => $t(`trigger.table.${item.text}`)"
                      clearable
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="search.trigger.timingChannel"
                      :items="triggerChannels"
                      clearable
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      v-model="search.trigger.timestamp"
                      type="text"
                    ></v-text-field>
                  </td>
                </tr>
              </template>

              <template v-slot:item.deviceId="{ item }">
                <router-link
                  :to="{ name: 'DevicesEdit', params: { id: item.deviceId } }"
                  exact
                >
                  {{ item.deviceId }}
                </router-link>
              </template>
              <template v-slot:item.blocked="{ item }">
                {{ getTriggerStatus(item) }}
              </template>
              <template v-slot:item.startNumber.type="{ item }">
                {{
                  $t(
                    `trigger.table.${String(
                      item.startNumber.type
                    ).toLowerCase()}`
                  )
                }}
              </template>
              <template v-slot:item.timestamp="{ item }">
                <span style="white-space:nowrap">
                  {{
                    formatTimestamp_100ns(
                      item.timestamp,
                      item.timeOffset,
                      false
                    )
                  }}
                </span>
              </template>
            </v-data-table>
          </div>
        </v-expand-transition>

        <v-expand-transition v-if="isOwner">
          <LogHistory
            v-show="showLog"
            :isVisible="showLog"
            :event="event"
            @editLogEntry="editLogEntry"
          />
        </v-expand-transition>

        <v-expand-transition v-if="isOwner && event.setup.useTolerances">
          <Tolerances
            v-show="showTolerances"
            :event="event"
            :heat="activeHeat"
            @referenceUpdate="handleReferenceUpdate"
          />
        </v-expand-transition>

        <v-container :fluid="$vuetify.breakpoint.lgAndDown">
          <v-row>
            <v-col cols="12">
              <v-card-title class="px-0 pb-0 d-flex">
                {{ $t("events.live") }}

                <TableFilter :event="event" reduced />

                <v-menu v-if="isOwner">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="ml-auto">
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat>
                    <v-subheader>
                      {{ $t("events.heats.actions.runs") }}
                    </v-subheader>
                    <v-list-item-group>
                      <v-list-item @click="openTimesImportDialog">
                        <v-list-item-content>
                          {{ $t("events.heats.actions.importTimes") }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="openStartTimeAssignmentDialog">
                        <v-list-item-content>
                          {{ $t("events.heats.actions.assignStartTimes") }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="finishHeatHandler">
                        <v-list-item-content>
                          {{ $t("events.heats.actions.finishHeat") }}
                        </v-list-item-content>
                        <v-tooltip top max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="ml-1" small
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          {{ $t("events.heats.actions.finishHeatTooltip") }}
                        </v-tooltip>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-card-title>
            </v-col>
          </v-row>
        </v-container>

        <LiveTable
          :event="event"
          :heat="activeHeat"
          :serverTimestamp="serverTimestamp"
          @cancel-action="cancelCompetitor"
          @open-edit-action="openCompetitorDialog"
          @open-edit-run-action="openCompetitorRunEditDialog"
          @restart-action="restartCompetitorRun"
          @edit-competitor-comment="openCompetitorCommentDialog"
        />
      </div>

      <div v-else-if="selectedHeat !== null && selectedHeat === 'TOTAL'">
        <div v-for="heat in activeHeats" :key="heat.id">
          <v-container :fluid="$vuetify.breakpoint.lgAndDown">
            <v-row>
              <v-col cols="12">
                <v-card-title class="px-0 pb-0">
                  {{ heat.name }}
                  <TableFilter :event="event" reduced />
                </v-card-title>
              </v-col>
            </v-row>
          </v-container>

          <LiveTable
            :event="event"
            :heat="heat"
            :serverTimestamp="serverTimestamp"
            @cancel-action="cancelCompetitor"
            @open-edit-action="openCompetitorDialog"
            @open-edit-run-action="openCompetitorRunEditDialog"
            @restart-action="restartCompetitorRun"
            @edit-competitor-comment="openCompetitorCommentDialog"
          />
        </div>
      </div>

      <div v-if="activeHeat">
        <div class="d-flex justify-end flex-column flex-md-row ma-2 ma-md-4">
          <ResultsExportMenu :event="event" />

          <PrintRanking :event="event" :heat="activeHeat" />
        </div>

        <HeatRanking
          :event="event"
          :heat="activeHeat"
          @open-edit-action="openCompetitorDialog"
          @open-edit-run-action="openCompetitorRunEditDialog"
          @referenceUpdate="handleReferenceUpdate"
          @edit-competitor-comment="openCompetitorCommentDialog"
        />
      </div>

      <div v-else>
        <div class="d-flex justify-end flex-column flex-md-row ma-2 ma-md-4">
          <ResultsExportMenu :event="event" />

          <PrintRanking :event="event" />
        </div>

        <EventRanking
          :event="event"
          :heat="activeHeat"
          @open-edit-action="openCompetitorDialog"
        />
      </div>
    </div>

    <v-card-subtitle v-else>
      {{ $t("events.noHeats") }}
    </v-card-subtitle>

    <CompetitorDialog ref="competitorDialog" :event="event" />

    <CompetitorRunEditDialog
      v-if="isOwner"
      ref="competitorRunEdit"
      :event="event"
    />

    <LogEntryEdit ref="logEntryEdit" :event="event" />

    <CompetitorCommentEdit
      ref="competitorCommentEdit"
      :event="event"
      :heat="activeHeat"
    />

    <TimesImportDialog
      ref="timesImportDialog"
      :event="event"
      :heat="activeHeat"
    />

    <StartTimeAssignmentDialog
      ref="startTimeAssignmentDialog"
      :event="event"
      :heat="activeHeat"
    />
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import Section from "@/components/Section";
import Timer from "@/components/Timer";
import TimerDiff from "@/components/TimerDiff";
import CountryFlag from "@/components/CountryFlag";
import Trophy from "@/components/Trophy";
import CompetitorRunEditDialog from "@/components/CompetitorRunEditDialog";
import LogHistory from "@/components/LogHistory";
import LogEntryEdit from "@/components/LogEntryEdit";
import LogDownloader from "@/components/LogDownloader";
import LiveTable from "@/components/LiveTable";
import TableFilter from "@/components/TableFilter";
import Tolerances from "@/components/Tolerances";
import ResultsExportMenu from "@/components/ResultsExportMenu";
import PrintRanking from "@/components/PrintRanking";
import HeatRanking from "@/components/HeatRanking";
import EventRanking from "@/components/EventRanking";
import CompetitorDialog from "@/components/CompetitorDialog";
import TimesImportDialog from "@/components/TimesImportDialog";
import CompetitorCommentEdit from "@/components/CompetitorCommentEdit";
import StartTimeAssignmentDialog from "@/components/StartTimeAssignmentDialog";
import formatRunTime from "@/mixins/formatRunTime";
import formatTimestamp from "@/mixins/formatTimestamp";
import cropTime from "@/mixins/cropTime";
import deviceTypes from "@/utils/deviceTypes";

export default {
  name: "TabLive",
  components: {
    Section,
    Timer,
    TimerDiff,
    CountryFlag,
    Trophy,
    CompetitorRunEditDialog,
    LogHistory,
    LogEntryEdit,
    LogDownloader,
    LiveTable,
    TableFilter,
    Tolerances,
    ResultsExportMenu,
    PrintRanking,
    HeatRanking,
    EventRanking,
    CompetitorDialog,
    TimesImportDialog,
    CompetitorCommentEdit,
    StartTimeAssignmentDialog
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      selectedHeat: null,
      heatIsLoading: false,
      showTolerances: false,
      showTriggers: false,
      showLog: false,
      loadingTriggers: false,
      clearTriggers: {
        loading: false,
        color: "primary"
      },
      resetTriggers: {
        loading: false,
        color: "primary"
      },
      timestamp: Date.now(),
      rAF: {
        requestId: null,
        now: null,
        then: Date.now(),
        interval: 1000 / 10,
        delta: null
      },
      search: {
        trigger: {
          deviceId: "",
          deviceName: "",
          status: "",
          startNumber: "",
          type: "",
          timingChannel: "",
          timestamp: ""
        }
      },
      triggerStatus: [
        {
          value: "ACTIVE",
          text: "active"
        },
        {
          value: "BLOCKED",
          text: "blocked"
        },
        {
          value: "DELETED",
          text: "deleted"
        }
      ],
      triggerTypes: [
        {
          value: "SEQUENTIAL",
          text: "sequential"
        },
        {
          value: "MANUAL",
          text: "manual"
        },
        {
          value: "SUGGESTED",
          text: "suggested"
        },
        {
          value: "MEMO",
          text: "memo"
        },
        {
          value: "UNKNOWN",
          text: "unkown"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      eventTrigger: state => state.events.selectedItemTrigger,
      triggerAutoUpdate: state => state.events.triggerAutoUpdate,
      devices: state => state.devices.items,
      serverTime: state => state.time.serverTime,
      localTimeStamp: state => state.time.localTimeStamp
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById"
    }),
    heats() {
      return this.event ? this.event.heats : null;
    },
    rankedHeats() {
      return this.heats?.filter(el => !el.excludeFromOverallRanking) ?? [];
    },
    overallRankingEntries() {
      return (
        this.event?.overallRanking?.entries?.filter(
          el => el.state === "FINISHED"
        ) ?? []
      );
    },
    serverTimestamp() {
      if (this.serverTime && this.localTimeStamp) {
        const diff_ms = this.timestamp - this.localTimeStamp;
        return this.serverTime.timestamp_100ns + diff_ms * 10000;
      }

      return null;
    },
    activeHeat() {
      if (this.selectedHeat !== null && this.selectedHeat !== "TOTAL") {
        return this.heats[this.selectedHeat];
      }

      return null;
    },
    activeHeats() {
      if (this.heats && this.heats.length > 0) {
        return this.heats.filter(heat => heat.state === "LIVE");
      }

      return null;
    },
    triggerHeaders() {
      return [
        {
          text: this.$i18n.t("trigger.table.deviceId"),
          value: "deviceId",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.deviceId).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.deviceName"),
          value: "device.deviceComponents.AppSettings.name",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.deviceName).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.status"),
          value: "blocked",
          filter: (value, search, item) => {
            switch (this.search.trigger.status) {
              case "ACTIVE":
                return !value && item.type === "StartNumberTrigger";
              case "BLOCKED":
                return value && item.type === "StartNumberTrigger";
              case "DELETED":
                return item.type === "ClearTrigger";
              default:
                return true;
            }
          }
        },
        {
          text: this.$i18n.t("trigger.table.startNumber"),
          value: "startNumber.startNumber",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.startNumber).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.type"),
          value: "startNumber.type",
          filter: value => {
            if (this.search.trigger.type) {
              return String(value)
                .toLowerCase()
                .includes(String(this.search.trigger.type).toLowerCase());
            }
            return true;
          }
        },
        {
          text: this.$i18n.t("trigger.table.timingChannel"),
          value: "timingChannel",
          filter: value => {
            if (this.search.trigger.timingChannel) {
              return String(value)
                .toLowerCase()
                .includes(
                  String(this.search.trigger.timingChannel).toLowerCase()
                );
            }
            return true;
          }
        },
        {
          text: this.$i18n.t("trigger.table.timestamp"),
          value: "timestamp",
          filter: (value, search, item) => {
            return this.formatTimestamp_100ns(value, item.timeOffset)
              .toLowerCase()
              .includes(String(this.search.trigger.timestamp).toLowerCase());
          }
        }
      ];
    },
    indexedTriggerWithDeviceData() {
      if (this.eventTrigger && this.devices) {
        return this.eventTrigger.map((trigger, index) => ({
          id: index,
          ...trigger,
          device: this.$store.getters["devices/getItemById"](trigger.deviceId)
        }));
      }

      return [];
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    leader() {
      if (
        this.activeHeat &&
        this.activeHeat.ranking.entries.length > 0 &&
        this.activeHeat.ranking.entries[0].runTime > 0 &&
        this.activeHeat.ranking.entries[0].state !== "CANCELLED"
      ) {
        return this.activeHeat.ranking.entries[0];
      }

      return null;
    },
    triggerChannels() {
      return deviceTypes["MT1"].channels.map(channel => ({
        value: channel.name,
        text: channel.name
      }));
    },
    autoUpdate: {
      get() {
        return this.triggerAutoUpdate ? true : undefined;
      },
      set() {
        this.toggleTriggerAutoUpdate();
      }
    },
    isRegularity() {
      return this.event.setup.regularitySettings.enabled;
    },
    isTargetTime() {
      return this.event.setup.regularitySettings.type === "TARGET_TIME";
    }
  },
  mixins: [formatRunTime, formatTimestamp, cropTime],
  watch: {
    competitorStart(newCompetitorStart, oldCompetitorStart) {
      if (
        newCompetitorStart &&
        oldCompetitorStart &&
        newCompetitorStart.id !== oldCompetitorStart.id
      ) {
        this.$refs.startSection.highlightSection();
      }
    },
    showTriggers(newShowTriggers) {
      if (newShowTriggers && this.eventTrigger === null) {
        this.handleFetchEventTriggers();
      }
    }
  },
  methods: {
    ...mapMutations({
      clearSelectedItemTrigger: "events/clearSelectedItemTrigger",
      toggleTriggerAutoUpdate: "events/toggleTriggerAutoUpdate"
    }),
    ...mapActions({
      cancelRunByCompetitor: "events/cancelRunByCompetitor",
      restartRunByCompetitor: "events/restartRunByCompetitor",
      armHeat: "events/armHeat",
      disarmHeat: "events/disarmHeat",
      cancelHeat: "events/cancelHeat",
      finishHeat: "events/finishHeat",
      setReferenceRunOfHeat: "events/setReferenceRunOfHeat",
      setReferenceRunOfEvent: "events/setReferenceRunOfEvent",
      updateEventSettingsTriggerFilterTime:
        "events/updateEventSettingsTriggerFilterTime",
      fetchSelectedItemTrigger: "events/fetchSelectedItemTrigger",
      addCompetitorsToItemHeat: "events/addCompetitorsToItemHeat"
    }),
    handleFetchEventTriggers() {
      this.loadingTriggers = true;

      const payload = {
        eventId: this.event.id
      };

      this.fetchSelectedItemTrigger(payload)
        .then(() => {
          this.loadingTriggers = false;
        })
        .catch(() => {
          this.loadingTriggers = false;
        });
    },
    handleTriggerReload() {
      this.handleFetchEventTriggers();
    },
    handleTriggerClear() {
      this.clearTriggers.loading = true;

      const payload = {
        eventId: this.event.id,
        data: {
          triggerFilterTime: Date.now()
        }
      };

      this.updateEventSettingsTriggerFilterTime(payload)
        .then(() => {
          this.clearSelectedItemTrigger();
          this.clearTriggers.loading = false;
        })
        .catch(() => {
          this.clearTriggers.loading = false;
          this.clearTriggers.color = "error";

          setTimeout(() => {
            this.clearTriggers.color = "primary";
          }, 2000);
        });
    },
    handleTriggerReset() {
      this.resetTriggers.loading = true;

      const payload = {
        eventId: this.event.id,
        data: {
          triggerFilterTime: 0
        }
      };

      this.updateEventSettingsTriggerFilterTime(payload)
        .then(() => {
          this.resetTriggers.loading = false;
          this.handleFetchEventTriggers();
        })
        .catch(() => {
          this.resetTriggers.loading = false;
          this.resetTriggers.color = "error";

          setTimeout(() => {
            this.resetTriggers.color = "primary";
          }, 2000);
        });
    },
    updateTimestamp() {
      this.rAF.requestId = requestAnimationFrame(this.updateTimestamp);

      this.rAF.now = Date.now();
      this.rAF.delta = this.rAF.now - this.rAF.then;

      if (this.rAF.delta > this.rAF.interval) {
        this.rAF.then = this.rAF.now - (this.rAF.delta % this.rAF.interval);

        this.timestamp = Date.now();
      }
    },
    armedState(index) {
      return this.heats[index].state === "LIVE";
    },
    updateHeatArmedStatus(index) {
      this.selectedHeat = index;

      const payload = {
        eventId: this.event.id,
        heatId: this.heats[index].id
      };

      if (this.heats[index].state === "LIVE") {
        this.disarmHeat(payload)
          .then(response => {
            this.$emit("disarming", true, response);
          })
          .catch(response => {
            this.$emit("disarming", false, response);
          });
      } else {
        this.armHeat(payload)
          .then(response => {
            this.$emit("arming", true, response);
            if (
              this.activeHeat.competitors.length === 0 &&
              this.event.competitors.length > 0
            ) {
              this.autoAddCompetitorsToHeat();
            }
          })
          .catch(response => {
            this.$emit("arming", false, response);
          });
      }
    },
    autoAddCompetitorsToHeat() {
      if (this.event.competitors.length > 0) {
        const validCompetitors = this.event.competitors.filter(
          el => el.startNumber
        );
        if (validCompetitors.length > 0) {
          const payload = {
            eventId: this.event.id,
            heatId: this.activeHeat.id,
            data: validCompetitors.map(el => el.id)
          };

          this.addCompetitorsToItemHeat(payload)
            .then(response => {
              this.$emit("autoCompetitorAdd", true, response);
            })
            .catch(response => {
              this.$emit("autoCompetitorAdd", false, response);
            });
        }
      }
    },
    openCompetitorDialog(competitor) {
      this.$refs.competitorDialog.openDialog(competitor);
    },
    openCompetitorRunEditDialog(heatId, competitorId) {
      this.$refs.competitorRunEdit.openDialog(heatId, competitorId);
    },
    openCompetitorCommentDialog(competitorId) {
      this.$refs.competitorCommentEdit.openDialog(competitorId);
    },
    preFilledHeat() {
      // SCHEDULED, LIVE, ACTIVE, INACTIVE, FINISHED
      // 1. Erster LIVE
      // 2. Erster SCHEDULED
      // 3. Letzer FINISHED
      // 4. Erster INACTIVE

      if (this.heats && this.heats.length > 0) {
        const liveHeats = this.heats.filter(heat => heat.state === "LIVE");
        if (liveHeats.length > 0) return liveHeats[0];

        const scheduledHeats = this.heats.filter(
          heat => heat.state === "SCHEDULED"
        );
        if (scheduledHeats.length > 0) return scheduledHeats[0];

        const finishedHeats = this.heats.filter(
          heat => heat.state === "FINISHED"
        );
        if (finishedHeats.length > 0) {
          if (finishedHeats.length === this.heats.length) {
            return "TOTAL";
          }
          return finishedHeats[finishedHeats.length - 1];
        }

        const inactiveHeats = this.heats.filter(
          heat => heat.state === "INACTIVE"
        );
        if (inactiveHeats.length > 0) return inactiveHeats[0];
      }

      return null;
    },
    preFilledHeatIndex(preFilledHeat) {
      return this.heats.findIndex(heat => heat.id === preFilledHeat.id);
    },
    cancelCompetitor(heatId, competitorId, reason) {
      const payload = {
        eventId: this.event.id,
        heatId,
        competitorId,
        data: {
          message: "",
          reason
        }
      };

      this.cancelRunByCompetitor(payload);
    },
    restartCompetitorRun(heatId, competitorId, includeStartgroup) {
      const runId = this.activeHeat.heatCycle.entries.filter(
        entry => entry.competitorId === competitorId
      )[0].runId;
      const payload = {
        eventId: this.event.id,
        heatId,
        runId,
        includeStartgroup
      };

      let confirmationMessage;
      let groupSize;
      if (includeStartgroup) {
        const fieldId = this.event.settings.massStartFieldId;
        if (String(fieldId).toLowerCase() === "all") {
          groupSize = this.event.competitors.length;
        } else {
          const competitor = this.getCompetitorById(competitorId);
          const group = this.event.competitors.filter(
            el => el.userData[fieldId] === competitor.userData[fieldId]
          );
          groupSize = group.length;
        }

        confirmationMessage = this.$i18n.t("events.restartGroupConfirmation", {
          groupSize
        });
      } else {
        confirmationMessage = this.$i18n.t("events.restartRunConfirmation");
      }
      confirm(confirmationMessage) && this.restartRunByCompetitor(payload);
    },
    restartHeat() {
      if (confirm(this.$i18n.t("events.restartHeatConfirmation"))) {
        this.heatIsLoading = true;

        const payload = {
          eventId: this.event.id,
          heatId: this.activeHeat.id
        };

        this.cancelHeat(payload)
          .then(() => {
            this.heatIsLoading = false;
          })
          .catch(() => {
            this.heatIsLoading = false;
          });
      }
    },
    finishHeatHandler() {
      if (confirm(this.$i18n.t("events.finishHeatConfirmation"))) {
        this.heatIsLoading = true;

        const payload = {
          eventId: this.event.id,
          heatId: this.activeHeat.id
        };

        this.finishHeat(payload)
          .then(() => {
            if (this.activeHeat.state === "LIVE") {
              this.disarmHeat(payload)
                .then(response => {
                  this.$emit("disarming", true, response);
                })
                .catch(response => {
                  this.$emit("disarming", false, response);
                });
            }

            this.heatIsLoading = false;
          })
          .catch(() => {
            this.heatIsLoading = false;
          });
      }
    },
    handleReferenceUpdate(success, response) {
      this.$emit("referenceUpdate", success, response);
    },
    editLogEntry(logEntry) {
      this.$refs.logEntryEdit.openDialog(logEntry);
    },
    handleLogDownload(success) {
      this.$emit("logDownload", success);
    },
    getTriggerStatus(trigger) {
      if (trigger.type === "ClearTrigger") {
        return this.$i18n.t("trigger.table.deleted");
      }
      return trigger.blocked
        ? this.$i18n.t("trigger.table.blocked")
        : this.$i18n.t("trigger.table.active");
    },
    openTimesImportDialog() {
      this.$refs.timesImportDialog.openDialog();
    },
    openStartTimeAssignmentDialog() {
      this.$refs.startTimeAssignmentDialog.openDialog();
    }
  },
  mounted() {
    this.rAF.requestId = requestAnimationFrame(this.updateTimestamp);

    this.selectedHeat =
      this.preFilledHeat() === "TOTAL"
        ? "TOTAL"
        : this.preFilledHeatIndex(this.preFilledHeat());
  },
  beforeDestroy() {
    cancelAnimationFrame(this.rAF.requestId);
  }
};
</script>

<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
.v-data-table--dense /deep/ tbody tr:nth-child(even),
.v-data-table--dense /deep/ tbody tr:nth-child(even) .firstSectionColumn {
  background: #f7f7f7;
}
.firstSectionColumn {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
.v-data-table /deep/ tbody tr:hover .firstSectionColumn {
  background: #eeeeee;
}
th.clickable {
  cursor: pointer;
}
.v-data-table /deep/ tbody tr.v-data-table__selected {
  background: #e4f2fd;
}
.v-data-table--dense > .v-data-table__wrapper {
  overflow-y: hidden;
}
</style>
